.cover {
  background-image: linear-gradient(-150deg, #fff8e0 10%, rgb(0, 0, 0, 0%) 40%),
    linear-gradient(var(--yellow), var(--coral));
  min-height: 100vh;
  overflow: hidden;
  z-index: 0;
}

.logo {
  min-height: 55vh;
  max-width: 80vw;
  margin-top: -20px;
  animation: jackInTheBox;
  animation-duration: 2s;
}

.title {
  margin-bottom: 0px;
  opacity: 0%;
  animation: fadeIn, zoomIn;
  animation-delay: 2s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.subtitle {
  font-family: "Marsha Belle", sans-serif;
  opacity: 0%;
  animation: fadeIn, zoomIn;
  animation-delay: 3s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.light-rays {
  transform: rotate(-30deg);
  position: absolute;
  top: 95px;
  z-index: -1;
  width: 200%;
  left: -400px;
  margin-bottom: -500px;
  min-height: 500px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .logo {
    min-height: 100%;
    max-width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
}
