.section {
  padding: 60px;
  position: relative;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .section {
    padding: 60px 16px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
}
