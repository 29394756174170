.rsvp {
  background-color: var(--coral);
}

.rsvp form {
  background-color: rgb(197 96 87 / 60%);
  color: white;
  border-radius: 10px;
  padding: 32px 16px;
}

.rsvp fieldset {
  margin-bottom: 32px;
  border-radius: 10px;
  border: white solid 1px;
}

.rsvp legend {
  font-weight: 600;
}

.rsvp select,
.rsvp input,
.rsvp textarea {
  padding: 10px 16px;
  border-radius: 5px;
  width: 45%;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(
    rgb(118, 118, 118),
    rgb(133, 133, 133)
  ) !important;
}

.rsvp select:disabled,
.rsvp input:disabled {
  color: rgb(170, 170, 170);
  background-color: white;
  opacity: 0.7;
  border-color: rgba(118, 118, 118, 0.3);
}

.rsvp .column {
  display: flex;
  flex-direction: column;
}

.rsvp input {
  width: 41%;
}

.rsvp textarea {
  box-sizing: border-box;
  width: 100%;
  resize: vertical;
  font-family: "Montserrat", sans-serif;
}

.rsvp textarea:disabled {
  color: rgb(170, 170, 170);
  background-color: white;
  opacity: 0.7;
  border-color: rgba(118, 118, 118, 0.3);
}

.rsvp .buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.rsvp a {
  text-decoration: underline;
  color: white;
}

.rsvp a:hover {
  text-decoration: underline;
  color: var(--yellow);
}

.rsvp .dropdown {
  position: relative;
  display: inline-block;
  width: 45%;
}

.rsvp .dropdown > input {
  box-sizing: border-box;
  width: 100%;
}

.rsvp .dropdown-btn {
  background-color: transparent;
  width: 100%;
}

.rsvp .dropdown-btn:hover {
  background-color: transparent;
  text-decoration: underline;
}

.rsvp .dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  margin: auto;
  box-sizing: border-box;
  width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .rsvp select,
  .rsvp input {
    box-sizing: border-box;
    width: 100%;
  }

  .rsvp .dropdown {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .rsvp select,
  .rsvp input {
    box-sizing: border-box;
    width: 100%;
  }

  .rsvp .dropdown {
    width: 100%;
  }
}
