@font-face {
  font-family: "Montserrat";
  src: url("../public/assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Hello Sunshine Marker";
  src: url("../public/assets/fonts/Hello-Sunshine-Marker.otf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Marsha Belle";
  src: url("../public/assets/fonts/Marsha-Belle.otf");
  font-weight: 100;
  font-display: swap;
}

:root {
  --yellow: #ffe381;
  --coral: #ff8383;
  --coral-dark: #c56057;

  --font-size: 16pt;

  overflow-x: hidden;
  max-width: 1000px;
  margin: auto;
  background-color: var(--coral-dark);
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: var(--font-size);
  line-height: 1.5em;
  box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.5);
}

h1,
h2,
h3,
h4 {
  font-family: "Hello Sunshine Marker", sans-serif;
  margin-top: 0px;
  font-weight: 100;
  line-height: 1em;
}

h1 {
  font-size: 9em;
}

h2 {
  font-size: 7em;
  margin-bottom: 0.5em;
}

h3 {
  font-size: 4em;
  margin-bottom: 0.3em;
}

h4 {
  font-size: 2.5em;
  margin-bottom: 0.3em;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.btn,
button {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  padding: 0.65em 1em;
  border-radius: 100px;
  border: none;
  text-decoration: none;
  font-size: 16px;
}

.btn-main {
  background-color: black;
  color: white;
}

.btn-main:disabled,
.btn-main:disabled:hover {
  background-color: black;
  color: white;
  opacity: 50%;
  cursor: default;
}

.btn-main:hover {
  background-color: white;
  color: black;
}

.btn-secondary {
  color: black;
  background-color: transparent;
  text-decoration: underline;
}

.btn-secondary:hover {
  color: white;
}

.btn-cta {
  opacity: 0%;
  animation: fadeIn, pulse;
  animation-delay: 4s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.mt-30 {
  margin-top: 30px;
}

.mt-60 {
  margin-top: 60px;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
body::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #555;
}
body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(to bottom, var(--yellow) 0%, var(--coral) 100%);
}
body::-webkit-scrollbar-thumb:hover {
  background: var(--yellow);
}
body::-webkit-scrollbar-thumb:active {
  background: var(--coral-dark);
}

em {
  background-color: white;
  color: var(--coral-dark);
  font-style: normal;
  font-weight: 600;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 6em;
  }

  h2 {
    font-size: 4em;
  }

  h3 {
    font-size: 3em;
  }

  h4 {
    font-size: 2em;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  h1 {
    font-size: 8em;
  }

  h2 {
    font-size: 6em;
  }

  h3 {
    font-size: 4em;
  }

  h4 {
    font-size: 2.5em;
  }
}
