.info-and-suggestions {
  background-color: var(--coral-dark);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2) inset;
  color: white;
}

.info-and-suggestions > div {
  border: 5px solid white;
  padding: 30px;
}

.list {
  text-align: left;
}
