.about-us {
  background-color: var(--coral);
}

.about-us .gap {
  gap: 10px;
}

.about-us .card-container {
  display: block;
}

.about-us .card {
  width: 270px;
  height: 270px;
  transition: all 0.6s ease;
  transform-style: preserve-3d;
  border-radius: 100%;
  border: 7px solid white;

  box-shadow: 0 27px 55px 0 rgba(0, 0, 0, 0.3),
    0 17px 17px 0 rgba(0, 0, 0, 0.15);
}

.about-us .front,
.back {
  position: absolute;
  background: white;
  color: black;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  backface-visibility: hidden;
}

.about-us .front {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.about-us .back {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
}

.about-us .card-container:hover .card {
  transform: rotateY(180deg);
}

.about-us .back {
  transform: rotateY(180deg);
}

.about-us .desktop {
  display: flex;
}

.about-us .mobile {
  display: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .about-us .desktop {
    display: none;
  }

  .about-us .mobile {
    display: flex;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .about-us .desktop {
    display: none;
  }

  .about-us .mobile {
    display: flex;
  }
}

/* Extra */
@media only screen and (min-width: 765px) and (max-width: 992px) {
  .about-us .card-container:last-child {
    display: none;
  }
}
