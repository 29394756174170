.gift {
  background-color: var(--coral-dark);
  color: white;
  box-shadow: 0 -10px 10px 0 rgba(0, 0, 0, 0.2) inset;
  margin-top: -60px;
}

.gift a {
  color: white;
}

.honeymoon {
  width: 100%;
}

.bank {
  padding: 32px;
  text-align: left;
  background-color: rgb(255 255 255 / 10%);
  border: 5px dotted white;
}
